import cls from "./ProductsTable.module.scss";
import ProductsTableRow from "../ProductsTableRow/ProductsTableRow";
import { DetailProduct } from "../../../redux/Tablet/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../../redux/store";
import { setCheckedShowingOrder } from "../../../redux/Tablet/TabletSlice";
import {
  getAssemblingOrders,
  patchCheckedOrderStatus,
} from "../../../redux/Tablet/actions";

interface ProductsTableProps {
  items: DetailProduct[];
  handleBeginCollect: () => void;
}
function ProductsTable({ handleBeginCollect, items }: ProductsTableProps) {
  const dispatch = useDispatch<TypedDispatch>();
  const { started } = useSelector(
    (state: RootState) => state.tablet.showingOrder
  );
  const { assemblingOrders, activeTab } = useSelector(
    (state: RootState) => state.tablet
  );
  // const
  const setChecked = (
    check: boolean,
    index: number,
    orderItemId: number,
    status: boolean
  ) => {
    status && handleBeginCollect();
    dispatch(patchCheckedOrderStatus({ orderItemId, status: !status }));
    dispatch(setCheckedShowingOrder({ index, check }));
    if (activeTab === "new") {
      dispatch(
        getAssemblingOrders({ currentPage: assemblingOrders.currentPage })
      );
    }
  };

  return (
    <div className={cls["productsTable"]}>
      <div className="h-[66px] px-[18px] lg:px-6 py-5 text-[#8E8E8E] text-[18px] font-extrabold flex items-center justify-between">
        <div>позиция</div>
        <div className="flex items-center gap-2 lg:gap-4">
          <div>количество</div>
          <div>добавлено</div>
        </div>
      </div>
      <div className="w-full flex flex-col overflow-scroll xl:text-xl px-[18px] max-h-full h-[calc(100%-66px)]">
        {items?.map((item: DetailProduct, index) => {
          return (
            <ProductsTableRow
              changeItems={item?.changeItems}
              key={item.orderItemId}
              started={started}
              quantity={item.quantity}
              status={item.status}
              id={item.id}
              orderItemId={item.orderItemId}
              title={
                item.title
                  ? item.title
                  : item.food
                  ? item.food.title
                  : item.combo?.title
              }
              image={
                item.image
                  ? item.image
                  : item.food
                  ? item.food.image
                  : item.combo?.image
              }
              setChecked={(arg, arg2, arg3, arg4) =>
                setChecked(arg, arg2, arg3, arg4)
              }
              setStart={handleBeginCollect}
              index={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ProductsTable;
