import TabletHeader from "./TabletHeader/TabletHeader";
import cls from "./TabletMain.module.scss";
import TabletSearch from "./TabletSearch/TabletSearch";
import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../redux/store";
import BlockedScreen from "./BlockedScreen/BlockedScreen";
import LogoutModal from "./LogoutModal/LogoutModal";
import TabletOrderList from "./TabletOrderList/TabletOrderList";
import TabletOrderDetail from "./TabletOrderDetail/TabletOrderDetail";
import { useCallback, useEffect, useState } from "react";
import {
  getCollectorOrder,
  getAssemblingOrders,
  getWaitingOrders,
} from "../../redux/Tablet/actions";
import {
  setLoading,
  setNewOrders,
  setOpenOrders,
} from "../../redux/Tablet/TabletSlice";
import { setAuthMessage, setErrorRequest } from "../../redux/User/UserSlice";
import { AxiosResponse } from "axios";
import { TabletOrders } from "../../redux/Tablet/types";
import { formatDate } from "../../utils/helpers";

function TabletMain() {
  const {
    searching,
    blockedScreen,
    loggingOutModalOpen,
    waitingOrders,
    newOrders,
    assemblingOrders,
    activeTab,
    ordersShouldUpdate,
  } = useSelector((state: RootState) => state.tablet);
  const { authMessage } = useSelector((state: RootState) => state.User);
  const [soketActive, setSoketActive] = useState(false)

  const dispatch = useDispatch<TypedDispatch>();

  useEffect(() => {
    if (authMessage !== "") {
      setTimeout(() => dispatch(setAuthMessage("")), 5000);
    }
  }, [authMessage, dispatch]);

  const connect = () => {
    const collectorString = localStorage.getItem("collectors");
    const collector = collectorString ? JSON.parse(collectorString) : {};
    const socket = new WebSocket(
      `${process.env.REACT_APP_BASE_URL_WS}/orders/?token=${collector.token}`
    );

    socket.onopen = () => {
      console.log("WebSocket connection established.");
      setSoketActive(true)
    };

    socket.onmessage = ({ data }: { data: string }) => {
      console.log("Received WebSocket message:", data);
      const dataParse: TabletOrders = JSON.parse(data);
      dataParse.active = false;
      dataParse.started = false;
      dataParse.date = formatDate(dataParse.date);
      //@ts-ignore
      dataParse.order_number = dataParse.order_number.slice(1);
      //@ts-ignore
      dataParse.id = Number(dataParse.order_number);
      //@ts-ignore
      dataParse.items = dataParse.order_items;
      console.log(dataParse);
      
      // newOrders.orders.find((item) => item.id === 12)
      dispatch(setNewOrders(dataParse));
    };

    socket.onerror = (err) => {
      socket.close();
      console.log(err, "error");
      setSoketActive(false)
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed.");
      setSoketActive(false)
    };
  };
  useEffect(() => {
    !soketActive&&
    connect();
  },[]);


  const fetchOrders = useCallback(
    (action: any, currentPage: any, activeTab: any) => {
      dispatch(action({ currentPage }))
        .unwrap()
        .then((response: AxiosResponse) => {
          dispatch(
            setOpenOrders({
              activeTab,
              types: activeTab,
              ids: response.data.results[0]?.id,
            })
          );
        })
        .catch((err: Error) => {
          dispatch(setLoading(false));
          dispatch(setErrorRequest(true));
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (activeTab === "new") {
      fetchOrders(getCollectorOrder, newOrders.currentPage, activeTab);
    } else if (activeTab === "waiting") {
      fetchOrders(getWaitingOrders, waitingOrders.currentPage, activeTab);
    } else if (activeTab === "assembling") {
      fetchOrders(getAssemblingOrders, assemblingOrders.currentPage, activeTab);
    }
  }, [
    fetchOrders,
    activeTab,
    ordersShouldUpdate,
    dispatch,
    newOrders.currentPage,
    waitingOrders.currentPage,
    assemblingOrders.currentPage,
  ]);


  useEffect(() => {
    if (!soketActive) {
      const intervalId = setInterval(function() {
        fetchOrders(getCollectorOrder, newOrders.currentPage, activeTab);
        connect();
      }, 60000)

      return clearTimeout(intervalId)
    } else {
    }
     
  }, [soketActive, connect]);

  return (
    <div className={cls["main"]}>
      <TabletHeader />
      {searching && <TabletSearch />}
      {blockedScreen && <BlockedScreen />}
      {loggingOutModalOpen && <LogoutModal />}
      <div
        className={searching ? cls["main__content-none"] : cls["main__content"]}
      >
        <div className={cls["main__content-leftBlock"]}>
          <TabletOrderList />
        </div>
        <div className={cls["main__content-rightBlock"]}>
          {<TabletOrderDetail />}
        </div>
      </div>
    </div>
  );
}
export default TabletMain;
