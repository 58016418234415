import cls from "./Login.module.scss";
import { ReactComponent as Logo } from "../../assets/new-logo.svg";
import Button from "../../components/Buttons/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../redux/store";
import { filterPhoneNum } from "../../utils/helpers";
import { useState } from "react";
import ErrorPopup from "../../components/Popup/ErrorPopup/ErrorPopup";
import { errorStatusVariant } from "../../components/types";
import { setAuthMessage, setErrorRequest, setLogin } from "../../redux/User/UserSlice";
import { setLoading } from "../../redux/Tablet/TabletSlice";
import ReactInputMask from "react-input-mask";
import { Navigate } from "react-router-dom";
import { logIn } from "../../redux/User/action";

function Login() {
  const dispatch = useDispatch<TypedDispatch>()
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { authMessage, login } = useSelector((state: RootState) => state.User)

  const handlerLogIn = () => {
    dispatch(logIn({
      phone_number: phoneNumber,
      password: password,
    })).unwrap().then(res => {
      dispatch(setLogin(true))
      dispatch(setAuthMessage(res.data.message))      
      }
      ).catch((err: Error) => {
      dispatch(setLoading(false))
      dispatch(setErrorRequest(true))
    })
  } 

  if (login) {
    return <Navigate to={'/'} />
  }

  return (
    <div className="w-full min-h-[600px] h-screen overflow-hidden bg-[#33a937] uppercase flex items-center place-content-around flex-col ">
      {
        authMessage &&
          <ErrorPopup status={errorStatusVariant.error}>
            <h5 style={{ color: '#F5F5F5' }}>{authMessage}</h5>
          </ErrorPopup>
      }
      <Logo className={cls.logo} fill='#ffffff' />
      <div className="bg-white max-w-[512px] w-full p-9 rounded-[36px] m-6">
        <h2 className="text-wrap m-0 leading-[110%] text-[40px] font-extrabold  text-[#575757] mb-6">
          авторизация
        </h2>
        <div className="w-full flex flex-col gap-4 mb-9">
            <label className="w-full h-[100%] gap-1 flex items-start place-content-between flex-col">
            <span className="text-sm font-bold">ваш номер</span>
              <ReactInputMask
                className=""
                mask="+7 (999) 999-99-99" 
                maskChar="_"
                placeholder="+7 (___) ___-__-__"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(filterPhoneNum(event.target.value))}
              />
            </label>
            <label className="w-full h-[100%] gap-1 flex items-start place-content-between flex-col">
            <span className="text-sm font-bold">пароль</span>
              <input
                type={'password'}
                placeholder='Введите ваш пароль'
                onChange={(event) => setPassword(event.target.value)}
              />
            </label>
        </div>
        <Button disabled={false} text={'ВОЙТИ'} fontsize={20} fontweight={700} handlerClick={handlerLogIn} />
      </div>
      <div className="h-[10%] flex items-center place-content-center flex-col">
        <p className="text-wrap m-0 text-3xl font-bold text-[#f9eb1d]">ваша улыбка</p>
        <p className="text-wrap m-0 text-3xl font-bold text-white">наша радость</p>
      </div>
    </div>
  );
}
export default Login;